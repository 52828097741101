<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton> </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            enableClearAll
            @all-search-cleared="clearSearch()"
            @refresh-clicked="refreshData()"
            enableRefresh
            @options-update="onTableOptionsChange"
            enableSearchDate
            datePlaceholder="上堂日期"
            @date-selected="searchAttendDate"
        ></Datatable>
    </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue';

export default {
    name: 'StudentAttendance',
    components: {
        Datatable,
    },
    computed: {
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        }
    },
    data: () => ({
        tableHeaders: [
            { value: 'date', text: '上堂日期' },
            { value: 'timeslot', text: '時間' },
            { value: 'course_code', text: '課程編號' },
            { value: 'course_name', text: '課程名稱' },
            { value: 'attendance', text: '出席狀態', align: 'center' },
        ],

        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        tableSearchOrderDate: '',
    }),
    methods: {
        async getAttendanceData() {
            this.tableLoading = true;
            try {
                let payload = {
                    filter_limit: this.tableLimit,
                    filter_page: this.tablePage - 1,
                    filter_item: [],
                };

                if (this.$validate.DataValid(this.tableSearchOrderDate)) {
                    payload.filter_item.push({ key: 'date', value: this.tableSearchOrderDate });
                }

                const { data, total } = await this.$Fetcher.GetStudentAttendances(this.id, payload);
                this.tableItemTotal = total;
                this.tableData = data.map(el => {
                    return {
                        id: el.lesson_id,
                        date: el.date,
                        start_time: el.start_time,
                        end_time: el.end_time,
                        course_code: el.course_code,
                        course_name: el.course_name,
                        attendance: el.attend_status
                    }
                })

                this.tableLoading = false;
            } catch(err) {
                this.$common.error(err);
                this.tableData = [];
                this.tableLoading = false;
            }
        },
        searchAttendDate(date) {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchOrderDate = date;
            this.getAttendanceData();
        },
        clearSearch() {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchOrderDate = '';
            this.getAttendanceData();
        },
        refreshData() {
            this.tableLimit = 10;
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.getAttendanceData();
        },
        
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getAttendanceData();
        },
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        if (this.$validate.DataValid(this.id)) {
            await this.getAttendanceData();
            this.$store.dispatch('toggleLoadingPage', false);
        }
    }
}
</script>